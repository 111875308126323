.ql-editor em {
  font-weight: inherit;
  font-style: italic;
}
.text-to-html-editor.email {
  overflow: unset;
}
.text-to-html-editor.email .ql-container {
  font-family: Arial, Helvetica, sans-serif;
}
.text-to-html-editor.email p, .text-to-html-editor.email h1, .text-to-html-editor.email h2, .text-to-html-editor.email h3 {
  all: revert;
}
.text-to-html-editor.email .ql-align-center {
  text-align: center;
}
.text-to-html-editor.email p {
  margin-bottom: 12px;
  line-height: 1.5;
  font-weight: normal;
}
.text-to-html-editor.email em {
  font-weight: inherit;
}
.text-to-html-editor.quill .ql-container {
  display: flex;
  height: 450px;
}
.text-to-html-editor.quill .ql-snow .ql-editor h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}
.text-to-html-editor.quill .ql-snow .ql-editor h2 {
  font-size: 17px;
  margin-bottom: 8px;
}
.text-to-html-editor.quill .ql-editor {
  flex: 1;
}
.text-to-html-editor.quill .ql-editor table {
  border: 1px solid black;
  border-collapse: collapse;
  margin: auto;
  max-width: 700px;
}
.text-to-html-editor.quill .ql-editor th, .text-to-html-editor.quill .ql-editor td {
  border: 1px solid black;
  padding: 10px;
}
.text-to-html-editor.quill .ql-editor li[data-list='bullet']:before {
  content: counter(list-0,disc) ' ';
}
.text-to-html-editor.quill h1:not(:first-of-type) {
  margin-top: 30px;
}
.text-to-html-editor.quill p {
  margin-bottom: 12px;
  line-height: 1.5;
  font-weight: normal;
}
.text-to-html-editor.quill.enhanced.highlight-on-focus:focus-within .ql-container.ql-snow, .text-to-html-editor.quill.enhanced.highlight-on-focus:focus-within .ql-toolbar.ql-snow {
  border-color: #2a5fe8;
}
